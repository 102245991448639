// Generated by Framer (3def70c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./m63bmoSPx-0.js";

const cycleOrder = ["r8ZzhLv_2", "wsoKgeDY3", "b0AkCpEjQ", "wrUX3wYSW", "WoKTYv5RP", "QCpgdCGwJ"];

const serializationHash = "framer-sJgZQ"

const variantClassNames = {b0AkCpEjQ: "framer-v-62fhtq", QCpgdCGwJ: "framer-v-1y3kzgn", r8ZzhLv_2: "framer-v-p2k82z", WoKTYv5RP: "framer-v-rm7hpe", wrUX3wYSW: "framer-v-v0ur9u", wsoKgeDY3: "framer-v-17r0s2n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {L: "QCpgdCGwJ", Large: "b0AkCpEjQ", M: "WoKTYv5RP", Mid: "wsoKgeDY3", S: "wrUX3wYSW", Small: "r8ZzhLv_2"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, BFkPhZWDK: mouseEnter ?? props.BFkPhZWDK, D3CuSuy0M: image ?? props.D3CuSuy0M ?? {src: "https://framerusercontent.com/images/eNZC4oqE3hf7EAVMlU6VGIeLDc.png"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "r8ZzhLv_2"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, D3CuSuy0M, BFkPhZWDK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "r8ZzhLv_2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1uah0z4 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (BFkPhZWDK) {const res = await BFkPhZWDK(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-p2k82z", className, classNames)} data-framer-name={"Small"} data-highlight layoutDependency={layoutDependency} layoutId={"r8ZzhLv_2"} onMouseEnter={onMouseEnter1uah0z4} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({b0AkCpEjQ: {"data-framer-name": "Large"}, QCpgdCGwJ: {"data-framer-name": "L"}, WoKTYv5RP: {"data-framer-name": "M"}, wrUX3wYSW: {"data-framer-name": "S"}, wsoKgeDY3: {"data-framer-name": "Mid"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "70px", ...toResponsiveImage(D3CuSuy0M)}} className={"framer-kq0oa3"} data-framer-name={"iMessage"} layoutDependency={layoutDependency} layoutId={"IJBzGxFQ3"} {...addPropertyOverrides({b0AkCpEjQ: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "110px", ...toResponsiveImage(D3CuSuy0M)}}, QCpgdCGwJ: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "66px", ...toResponsiveImage(D3CuSuy0M)}}, WoKTYv5RP: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "54px", ...toResponsiveImage(D3CuSuy0M)}}, wrUX3wYSW: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "42px", ...toResponsiveImage(D3CuSuy0M)}}, wsoKgeDY3: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "90px", ...toResponsiveImage(D3CuSuy0M)}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sJgZQ.framer-o0iuew, .framer-sJgZQ .framer-o0iuew { display: block; }", ".framer-sJgZQ.framer-p2k82z { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-sJgZQ .framer-kq0oa3 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 70px); overflow: visible; position: relative; width: 70px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sJgZQ.framer-p2k82z { gap: 0px; } .framer-sJgZQ.framer-p2k82z > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-sJgZQ.framer-p2k82z > :first-child { margin-left: 0px; } .framer-sJgZQ.framer-p2k82z > :last-child { margin-right: 0px; } }", ".framer-sJgZQ.framer-v-17r0s2n .framer-kq0oa3 { height: var(--framer-aspect-ratio-supported, 90px); width: 90px; }", ".framer-sJgZQ.framer-v-62fhtq .framer-kq0oa3 { height: var(--framer-aspect-ratio-supported, 110px); width: 110px; }", ".framer-sJgZQ.framer-v-v0ur9u .framer-kq0oa3 { height: var(--framer-aspect-ratio-supported, 42px); width: 42px; }", ".framer-sJgZQ.framer-v-rm7hpe .framer-kq0oa3 { height: var(--framer-aspect-ratio-supported, 54px); width: 54px; }", ".framer-sJgZQ.framer-v-1y3kzgn .framer-kq0oa3 { height: var(--framer-aspect-ratio-supported, 66px); width: 66px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 70
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"wsoKgeDY3":{"layout":["auto","auto"]},"b0AkCpEjQ":{"layout":["auto","auto"]},"wrUX3wYSW":{"layout":["auto","auto"]},"WoKTYv5RP":{"layout":["auto","auto"]},"QCpgdCGwJ":{"layout":["auto","auto"]}}}
 * @framerVariables {"D3CuSuy0M":"image","BFkPhZWDK":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerm63bmoSPx: React.ComponentType<Props> = withCSS(Component, css, "framer-sJgZQ") as typeof Component;
export default Framerm63bmoSPx;

Framerm63bmoSPx.displayName = "iMessage";

Framerm63bmoSPx.defaultProps = {height: 70, width: 70};

addPropertyControls(Framerm63bmoSPx, {variant: {options: ["r8ZzhLv_2", "wsoKgeDY3", "b0AkCpEjQ", "wrUX3wYSW", "WoKTYv5RP", "QCpgdCGwJ"], optionTitles: ["Small", "Mid", "Large", "S", "M", "L"], title: "Variant", type: ControlType.Enum}, D3CuSuy0M: {__defaultAssetReference: "data:framer/asset-reference,eNZC4oqE3hf7EAVMlU6VGIeLDc.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, BFkPhZWDK: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(Framerm63bmoSPx, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})